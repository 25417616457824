import { useEffect, useState } from "react";
import {
  DictionaryComponent,
  Theme,
  AppConfig,
  Dictionary,
} from "njamed-dictionary-maker";

const jangunyTheme: Theme = {
  palette: {
    primary: "rgb(166, 43, 43)",
    secondary: "rgb(166, 43, 43)",
    tertiary: "rgb(242, 239, 237)",
    accent: "hsl(28, 60%, 90%)",
    accentText: "hsl(36, 19%, 40%)",
    paper: "hsl(30, 14%, 99%)",
    green: "rgb(224, 136, 60)",
    brown: "hsl(37, 16%, 42%)",
    text: "#222",
    lightText: "#777",
    background: "#212121",
    border: "#212121",
    error: "#f44336",
    success: "#4caf50",
    warning: "#ff9800",
    info: "#2196f3",
  },
  typography: {
    text1: `'Muli', serif`,
    text2: `'Meloso', serif`,
    displayFontFamily: "'Meloso', serif",
  },
};

const config: AppConfig = {
  title: "Bininj Kunwok",
  subtitle: "Pan-dialectal dictionary",
  audioCloudSrc: "https://res.cloudinary.com/dykmwkpgw/video/upload/njamed/",
  imgCloudSrc: "https://res.cloudinary.com/dykmwkpgw/image/upload/njamed/",
  appIcon: {
    src: "https://njamed.com/images/mimih.png",
    alt: "App icon",
    paddingVertical: 0,
  },
  sourceLanguage: {
    name: "English",
    shortName: "EN",
  },
  targetLanguage: {
    name: "Bininj Kunwok",
    shortName: "BK",
  },
  captchaPageText: {
    text: "# Welcome\n\nWelcome to Njamed, a dictionary of Bininj Kunwok. Bininj Kunwok includes the dialects of Kunwinjku, Kuninjku, Kune, Kundednjenghmi, Kundjeyhmi (formerly spelt 'Gundjeihmi') and Mayali. We pay our respect to all the Bininj elders, educators and literacy workers past, present and emerging.\n\n### Indigenous Cultural and Intellectual Property\n\nThe Bininj Kunwok Language is the Indigenous Cultural Intellectual Property of the Bininj people, and the right to use it is subject to traditional law and custom. This website is intended for use by individuals learning Bininj Kunwok for the purpose of communication. Any other use of the language or information on this site, including teaching, research, or any commercial use requires the consent of the community. Please contact the Bininj Kunwok Regional Language Centre Ltd (info@bininjkunwok.org.au) or the Maningrida Culture Office (Culture.Office@bawinanga.com) for any enquiries.",
  },
  icons: {
    Dictionary: "book",
    Categories: "bookshelf",
    About: "info",
  },
};

function App() {
  const [dictionary, setDictionary] = useState<Dictionary | null>(null);

  useEffect(() => {
    const dataPath = `${process.env.PUBLIC_URL}/data/normalized-entries.json`;
    fetch(dataPath)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Got the data!");
        console.log(data);

        setDictionary(data);
      });
  }, []);

  return (
    <DictionaryComponent
      data={dictionary}
      theme={jangunyTheme}
      appConfig={config}
    />
  );
}

export default App;
